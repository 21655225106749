@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap');
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
  /* font-weight: 500; */
  font-style: normal;
}
@font-face {
  font-family: 'Subscribe';
  src: url('./assets/fonts/Subscribe/Subscribe.ttf');
  /* font-weight: 500; */
  font-style: normal;
}
@font-face {
  font-family: 'Baloo2';
  src: url('./assets/fonts/Baloo2-VariableFont_wght.ttf');
  /* font-weight: 500; */
  font-style: normal;
}
@font-face {
  font-family: 'TenorSans';
  src: url('./assets/fonts/TenorSans-Regular.ttf');
  font-style: normal;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-smooth {
  scroll-behavior: smooth;
}
